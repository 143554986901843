<template>
  <v-content>
    <router-view />

    <supportdashboard-core-footer />
  </v-content>
</template>

<script>
  export default {
    name: 'supportdashboardCoreView',

    components: {
      supportdashboardCoreFooter: () => import('./Footer'),
    },
  }
</script>
